import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";

// CORE
const Home = React.lazy(() => import("./pages/Core/Home/Index"));

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ResetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgetPassword/Index.js")
);

// UserPanel
const Panel = React.lazy(() => import("./pages/Panel/Home/Index.js"));
const Calendar = React.lazy(() => import("./pages/Panel/Calendar/Index.tsx"));
const AddChild = React.lazy(() => import("./pages/Panel/AddChild/Index.js"));
const Diagnozy = React.lazy(() => import("./pages/Panel/Diagnozy/Index.js"));
const TeczkaPacjenta = React.lazy(() =>
  import("./pages/Panel/TeczkaPacjenta/Index.js")
);
const KonsultacjeOnline = React.lazy(() =>
  import("./pages/Panel/KonsultacjeOnline/Index.js")
);
const KonsultacjeStacjonarne = React.lazy(() =>
  import("./pages/Panel/KonsultacjeStacjonarne/Index.js")
);
const Child = React.lazy(() => import("./pages/Panel/Child/Index.js"));
const ChildTeczka = React.lazy(() =>
  import("./pages/Panel/DzieckoTeczka/Index.js")
);
const Profile = React.lazy(() => import("./pages/Panel/User/Index.js"));
const Faktury = React.lazy(() => import("./pages/Panel/Faktury/Index.js"));
const Notyfikacje = React.lazy(() =>
  import("./pages/Panel/Notifications/notification.js")
);

// Admin Panel
const Admin = React.lazy(() => import("./pages/Admin/Home/Index.js"));
const AdminDzieci = React.lazy(() => import("./pages/Admin/Dzieci/Index.js"));
const AdminDziecko = React.lazy(() => import("./pages/Admin/Dziecko/Index.js"));

const AdminNotyfikacje = React.lazy(() =>
  import("./pages/Admin/Notifications/notification.js")
);

const AdminUzytkownicy = React.lazy(() =>
  import("./pages/Admin/Uzytkownicy/Index.js")
);
const AdminUzytkownik = React.lazy(() =>
  import("./pages/Admin/Uzytkownik/Index.js")
);
const AdminKalendarz = React.lazy(() =>
  import("./pages/Admin/Kalendarz/Index.tsx")
);
const AdminTeczki = React.lazy(() => import("./pages/Admin/Teczki/Index.js"));
const AdminDodajTeczke = React.lazy(() =>
  import("./pages/Admin/TeczkiDodaj/Index.js")
);
const AdminTeczka = React.lazy(() => import("./pages/Admin/Teczka/Index.js"));
const AdminTerapeuci = React.lazy(() =>
  import("./pages/Admin/Terapeuci/Index.js")
);
const AdminTerapeuta = React.lazy(() =>
  import("./pages/Admin/Terapeuta/Index.js")
);
const AdminDzieckoTeczka = React.lazy(() =>
  import("./pages/Admin/DzieckoTeczka/Index.js")
);
// PANEL SPECJALISTY
const TeraupetaHome = React.lazy(() =>
  import("./pages/Terapeuta/Home/Index.js")
);

const TerapeutaPacjenci = React.lazy(() =>
  import("./pages/Terapeuta/Pacjenci/Index.js")
);
const TerapeutaPacjent = React.lazy(() =>
  import("./pages/Terapeuta/Pacjent/Index.js")
);

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          <Route path="/" element={<Home />} />

          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/przypomnij-haslo" element={<ResetPassword />} />
          {authCtx.isLoggedIn && (
            <>
              <Route path="/moje-konto" element={<Panel />} />
              <Route path="/notyfikacje" element={<Notyfikacje />} />
              <Route path="/moje-konto/:child_id" element={<Child />} />
              <Route
                path="/moje-konto/:child_id/:teczka_id"
                element={<ChildTeczka />}
              />
              <Route path="/faktury" element={<Faktury />} />
              <Route
                path="/konsultacje-stacjonarne"
                element={<KonsultacjeStacjonarne />}
              />
              <Route
                path="/konsultacje-online"
                element={<KonsultacjeOnline />}
              />
              <Route path="/dodaj-dziecko" element={<AddChild />} />
              <Route path="/kalendarz/:child_id" element={<Calendar />} />
              <Route path="/diagnozy" element={<Diagnozy />} />
              <Route path="/teczka-pacjenta" element={<TeczkaPacjenta />} />
              <Route path="/profil" element={<Profile />} />
              {/* TERAPEUTA */}

              <Route path="/terapeuta" element={<TeraupetaHome />} />
              <Route
                path="/terapeuta/pacjenci"
                element={<TerapeutaPacjenci />}
              />
              <Route
                path="/terapeuta/pacjent/:child_id"
                element={<TerapeutaPacjent />}
              />
              {/* ADMIN */}
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/dzieci" element={<AdminDzieci />} />
              <Route
                path="/admin/dzieci/:child_id"
                element={<AdminDziecko />}
              />
              <Route
                path="/admin/dzieci/:child_id/:teczka_id"
                element={<AdminDzieckoTeczka />}
              />
              <Route path="/admin/notyfikacje" element={<AdminNotyfikacje />} />
              <Route path="/admin/uzytkownicy" element={<AdminUzytkownicy />} />
              <Route path="/admin/terapeuci" element={<AdminTerapeuci />} />
              <Route
                path="/admin/terapeuta/:terapeuta_id"
                element={<AdminTerapeuta />}
              />
              <Route
                path="/admin/uzytkownicy/:user_id"
                element={<AdminUzytkownik />}
              />
              <Route path="/admin/kalendarz" element={<AdminKalendarz />} />
              <Route path="/admin/teczki" element={<AdminTeczki />} />
              <Route
                path="/admin/teczki/dodaj"
                element={<AdminDodajTeczke />}
              />
              <Route
                path="/admin/teczki/:teczka_id"
                element={<AdminTeczka />}
              />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
